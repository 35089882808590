import React from "react";
import Fluidos from "../../../images/fluidos.jpg";
import Paisaje from "../../../images/paisaje.jpg";

const Welcome = () => {
    return (
        <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="false">
            <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={Fluidos} className="c-fluidos d-block w-100" alt="fluidos" />
                    <div className="carousel-caption d-md-block">
                        <h1>¿Necesita desplazar Fluidos?</h1>
                        <h5>¿Problemas con sus sistemas hidráulicos?</h5>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={Paisaje} className="c-paisaje d-block w-100" alt="paisaje" />
                    <div className="carousel-caption d-md-block">
                        <h1>Estamos donde tu estás</h1>
                        <h5>Uribe 636 , oficina 707. Antofagasta</h5>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="https://picsum.photos/id/180/1000/417/" className="d-block w-100" alt="..." />
                    <div className="carousel-caption d-md-block">
                        <h1>Third slide label</h1>
                        <h5>Some representative placeholder content for the third slide.</h5>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Welcome;