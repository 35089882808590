const Servicios = [
    {
        "id": 1,
        "foto": "images/bombadespos.png",
        "servicio": "Bombas de Desplazamiento Positivo",
        "url": "bombas-desplazamiento-positivo",
        "icono": "fa-solid fa-gear fa-3x text-primary",
        "resumen": "Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo",
        "info": "<h5>Bombas de tornillo</h5><p>Las bombas de tornillo IMO entregan un flujo constante y sin pulsaciones para sus sistemas de lubricación, inyección de aceite o desplazamiento de fuel oil . Poseen una vasta oferta de modelos para poder configurar soluciones a su medida. Caudales: Desde 10 a 12.500 l/m</p><ul><li>Caudales: Desde 1 a 3300 gpm</li><li>Presión: Desde 50 hasta 4500 psig</li></ul><br/><h5>Bombas de engranaje interno</h5><p>Las bombas de engranaje interno IMO son utilizadas en la inyección de aceite a alta presión. Pueden ser configuradas en multitud de etapas para alcanzar muy altas presiones con una baja viscosidad. Su diseño de perfil de dientes patentado genera una bajísima pulsación del líquido desplazado.</p><ul><li>Caudales: Desde 4,2 a 397 l/m</li><li>Presión: Hasta 5000 psi</li></ul>"
    },
    {
        "id": 2,
        "foto": "images/bombasum.png",
        "servicio": "Bombas Electrosumergibles",
        "url": "bombas-electrosumergibles",
        "icono": "fa fa-3x fa-water text-primary",
        "resumen": "Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo",
        "info": "<p>Las bombas alemanas SPT Pumpen son utilizadas para una variedad de aplicaciones tales como drenaje, manejo de lodos, aguas residuales, mezcla de arena, bentonita, etc. Además dado los múltiples usos que poseen, ofrecen una amplia selección de modelos portátiles, estacionarias, de acero inoxidable, etc.</p><ul><li>Particulado: Hasta 150 mm</li><li>Caudal: hasta 1224 m3/h</li></ul>"
        
    },
    {
        "id": 3,
        "foto": "images/bombasgen.png",
        "servicio": "Bombas",
        "url": "bombas",
        "icono": "fa fa-3x fa-droplet text-primary",
        "resumen": "Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo",
        "info": "<p>SGCM posee un amplio catálogo de bombas para asistirlos en sus procesos de desplazamiento de fluidos, inyección de combustible, drenaje, vaciado de tanques, etc.</p><h5>Bombas de paleta</h5><p>Las bombas de paleta B&C Hydraulics poseen un diseño de montaje de catridge que permite una rápida inspección de sus componentes objetos de desgaste, lo que disminuye notoriamente los tiempos de parada, ya que no es necesario desconectar la bomba del circuito.</p><p>Sus modelos están pensados para poder reemplazar tanto metrológicamente como en performance a marcas como CAT, Rexroth, Parker, Vickers y Eaton.</p><ul><li>Caudales: Desde 4,7 l/m hasta 640 l/m @1500 rpm</li><li>Presión: Hasta 275 bar</li></ul><h5>Bombas de engranajes externo</h5><p>Las bombas de engranaje externo B&C Hydraulics son de diseño modular pudiendo apilar las etapas y ser configuradas para diversos caudales o presiones.</p><p>Rangos de operación</p><ul><li>Caudales: Desde 5,7 a 153 l/m</li><li>Presión: Desde 150 hasta 300 bar</li></ul>"
    },
    {
        "id": 4,
        "foto": "images/filtros.png",
        "servicio": "Filtros",
        "url": "filtros",
        "icono": "fa fa-3x fa-filter text-primary",
        "resumen": "Duo dolore et diam sed ipsum stet amet duo diam. Rebum amet ut amet sed erat sed sed amet magna elitr amet kasd diam duo",
        "info": "<p>A la hora de calcular un sistema hidráulico, un filtrado adecuado es esencial ya que aumenta considerablemente el ciclo de vida de las partes que lo componen. Las variables relevantes para ello son el flujo y la presión del sistema, el tipo y grado de contaminación del fluido, las condiciones ambientales y las tolerancias de los equipos.</p><p>SGCM fluid ofrece distintas soluciones de filtrado para los diversos requerimientos que su empresa pueda tener:</p><h5>Filtros de succión</h5><p>Los filtros de entrada protegen a las bombas de la contaminación, además de ofrecer una difusión de flujo adicional a la línea de succión.</p><p>Flujos de hasta 1000 l/m</p><h5>Filtros Retorno</h5><p>Los filtros de retorno limpian el aceite hidráulico desde el sistema al estanque, previniendo que partículas externas o internas entren al sistema.</p><p>Flujos de hasta 3500 l/m</p><h5>Filtros de baja y media presión</h5><p>Los LMP son filtros para en línea para el retorno  ( <80 bar), con una diversidad de modelos que permite  un manejo de flujos bajos y altos. También pueden configurarse como filtros Dúplex.</p><p>Flujos de hasta 4500 l/m, Max P. 80 bar</p><h5>Filtros de alta presión</h5><p>Los filtros HP están diseñados para soportar las más adversas condiciones. Los filtros son dimensionados respecto de las necesidades de flujo específicas del sistema.</p><p>Flujos de hasta 630 l/m, Max P. 560 bar</p>"
    },
]

export default Servicios;