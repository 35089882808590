import React from "react";
import Bombas from "../../../images/bombas.jpg";

const Us = () => {
    return (
        <div className="container us py-5" id="nosotros">
            <div className="row">
                <div className="col-12 col-lg-7">
                    <h1 className="text-uppercase mb-4">Somos <span className="txt-primary"><strong>expertos</strong></span> en la industria de desplazamiento de fluidos</h1>
                    <h4 className="text-uppercase mb-3 text-body">Somos un equipo de profesionales con más de 30 años de experiencia en el desplazamiento de fluidos.</h4>
                    <p>Como socios estratégicos de su negocio, buscamos entregar soluciones integrales que le ayuden a disminuir costos, mejorar el rendimiento y alargar la vida útil de sus equipos, y en general, la de robustecer la operación de sus sistemas hidráulicos. Alguno de nuestros productos y servicios son:</p>
                    <div className="row gx-5 py-2">
                        <div className="col-12 col-md-6 mb-md-2 pe-0">
                            <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Procesos oleohidráulicos en Minería</p>
                            <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Generación Eléctrica</p>
                            <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Marina Comercial</p>
                        </div>
                        <div className="col-12 col-md-6 mb-2 pe-0">
                            <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Bombas de Desplazamiento Positivo</p>
                            <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Bombas Electrosumergibles</p>
                            <p className="fw-bold mb-2"><i className="fa fa-check text-primary me-3"></i>Entre otros más</p>
                        </div>
                    </div>
                    {/* <p className="mb-4">Tempor erat elitr at rebum at at clita aliquyam consetetur. Diam dolor diam ipsum et, tempor voluptua sit consetetur sit. Aliquyam diam amet diam et eos labore</p> */}
                </div>
                <div className="col-12 col-lg-5 pa-5 d-flex jc-c ai-c ma-auto">
                    <div className="bg-dark-radial">
                        <img className="usPhoto" src={Bombas} alt="Nosotros" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Us;