import React from "react";

const Electricidad = () => {
    return (
        <div className="cont-electricidad">
            <div className="txt-electricidad">
                    <h2>GENERACIÓN ELECTRICA</h2>
                    <p>Como SGCM fluid entendemos esto, y es por ello que poseemos un equipo de profesionales expertos en la mantención de los equipos hidráulicos que provee, los cuales además están capacitados para ofrecer soluciones integrales 24/7 frente a las más diversas dificultades que puedan surgir en terreno.</p>
            </div>
        </div>
    );
}

export default Electricidad;