import React from "react";

const Mineria = () => {
    return (
        <div className="cont-mineria">
            <div className="txt-mineria">
                    <h2>MINERÍA</h2>
                    <p>En SGCM fluid estamos preparados para asistirlos, ya sea proveyendo equipos de bombeo con comprobado éxito en terreno, asesorándolos en sus procesos de filtrado o desarrollar una solución para su problema específico.</p>
            </div>
        </div>
    );
}

export default Mineria;