import React from "react";

const Marina = () => {
    return (
        <div className="cont-marina">
            <div className="txt-marina">
                    <h2>MARINA COMERCIAL</h2>
                    <p>Para las plantas de procesamiento de pescado, SGCM fluid ofrece los equipos para inyectar el combustible a las calderas, así como un apoyo en el implementación de mejoras para estos.</p>
            </div>
        </div>
    );
}

export default Marina;