import React from "react";
import { Link, useParams } from "react-router-dom";

import Servicios from "../store/services.jsx";

const Product = props => {
    const { products } = useParams();
    console.log(products);
    return (
        <div className="container">
            {
                Servicios.map((e) => {
                    if (JSON.stringify(e.url) === JSON.stringify(products)) {
                        console.log(e.url);
                        return (
                            <div className="card mb-3" key={ e.id }>
                                <div className="row g-0">
                                    <div className="col-md-4 d-flex flex-column my-auto">
                                        <img src={e.foto} className="img-fluid rounded-start" alt="..." />
                                        <Link className="btn btn-danger" to="/">Volver</Link>
                                    </div>
                                    <div className="col-md-8 my-auto">
                                        <div className="card-body">
                                            <h3 className="card-title">{ e.servicio }</h3>
                                            <div dangerouslySetInnerHTML={{__html:e["info"]}}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div >
    );
}

export default Product;