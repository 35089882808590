import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from './components/footer';
import Navbar from './components/navbar';

import Home from './views/home';
import Product from './views/product';


const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/:products" element={<Product />} />
          <Route exact path="/sgcm.cl" element={<Home />} />
        </Routes>
        <a href="https://api.whatsapp.com/send?phone=%2B56942816563&text=Hola%21+Quiero+comunicarme+con+ustedes+para+ver+sus+productos+y+servicios" target="blank"><i className="fa-brands fa-square-whatsapp"></i></a>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
