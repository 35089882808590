import React from "react";
import Electricidad from "./sections/home/electricidad";
import Marina from "./sections/home/marina";
import Mineria from "./sections/home/mineria";
import Services from "./sections/home/services";
import Test from "./sections/home/text";
import Us from "./sections/home/us";
import Welcome from "./sections/home/welcome";

const Home = () => {
    return (
        <div className="home">
            <Welcome />
            <Mineria />
            <Us />
            <Electricidad />
            <Services />
            <Marina />
            {/* <Test /> */}
        </div>
    );
}

export default Home;