import React from "react";
import { Link } from "react-router-dom";

import Servicios from "../../../store/services";

const Services = props => {
    return (
        <div className="container-fluid bg-light py-5 px-5" id="pys">
            <div className="text-center mx-auto mb-5">
                <h1 className="display-5 text-uppercase mb-4">Nuestros <span className="txt-primary"><strong>productos y servicios</strong></span> son</h1>
            </div>
            <div className="row g-5">
                {
                    Servicios.map(e => {
                        return (
                            <div className="servhome col-lg-4 col-md-6 ">
                                <div className="service-item bg-white d-flex flex-column align-items-center text-center">
                                    <img className="img-fluid" src={e.foto} alt="" />
                                    <div className="service-icon bg-white">
                                        <i className={e.icono}></i>
                                    </div>
                                    <div className="px-4 pb-4">
                                        <h4 className="text-uppercase mb-3">{e.servicio}</h4>
                                        {/* <p>{e.resumen}</p> */}
                                        <Link className="btn btn-primary" to={`/${e.url}`}>Ver<i className="bi bi-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

export default Services;